<template>
    <div>
        <TheManageUsers/>
    </div>
</template>

<script>
import {
    TheManageUsers 
} from "@/components";

export default {
    name: "ManageUsers",
    components: {
        TheManageUsers
    } 
}
</script>
